.contact-header {
  position: relative;
  height: 800px;
  background-image: linear-gradient(
    180deg,
    var(--clr-white),
    var(--clr-primary-4)
  );
}
.contact-header h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 20vw; */
  padding-top: 60vw;
  color: var(--clr-primary-1);
}

.contact-image {
  border: solid 1px var(--clr-primary-5);
  position: absolute;
  top: 41%;
  left: 25%;
  border-radius: 50%;
  height: 200px;
  width: 200px;
}
.contact-icons {
  display: none;
}

.email-icon {
  position: absolute;
  top: 70%;
  left: 41%;
  width: 65px;
  height: 65px;
  color: var(--clr-primary-5);
  filter: grayscale(95%);
}
.footer-button-up {
  display: flex;
  justify-content: flex-end;
}
.button-up {
  margin-top: 400px;
  margin-right: 20px;
  width: 60px;
  height: 60px;
  color: var(--clr-primary-8);
}

@media screen and (min-width: 768px) {
  .contact-header h2 {
    padding-top: 30vw;
  }
  .contact-image {
    top: 41%;
    left: 36.5%;
  }
  .email-icon {
    top: 73%;
    left: 45.5%;
  }
}

@media screen and (min-width: 992px) {
  .contact-header {
    height: 900px;
  }
  .contact-header h2 {
    padding-top: 25vw;
  }
  .contact-image {
    height: 250px;
    width: 250px;
    top: 45%;
    left: 37%;
  }
  .email-icon {
    top: 80%;
    left: 46%;
    width: 80px;
    height: 80px;
    color: var(--clr-primary-5);
    filter: grayscale(95%);
  }
  .contact-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 450px;
  }
  .contact-icons .icon-1 {
    margin-left: 120px;
  }
  .contact-icons .icon-3 {
    margin-right: 360px;
  }

  .contact-icons .icon-1:hover {
    transform: none;
    transition: none;
  }
  .contact-icons .icon-3:hover {
    transform: none;
    transition: none;
  }
  .button-up {
    margin-top: 20px;
    margin-right: 30px;
    width: 60px;
    height: 60px;
    color: var(--clr-primary-8);
  }
}

@media screen and (min-width: 1440px) {
  .contact-header h2 {
    padding-top: 20vw;
  }
  .contact-image {
    height: 250px;
    width: 250px;
    top: 45%;
    left: 41%;
  }
  .email-icon {
    top: 80%;
    left: 47%;
  }
  .contact-icons {
    margin-top: 400px;
  }
  .contact-icons .icon-1 {
    margin-left: 120px;
  }
  .contact-icons .icon-3 {
    margin-right: 500px;
  }
}

@media screen and (min-width: 1900px) {
  .contact-header h2 {
    padding-top: 10vw;
  }
  .contact-image {
    height: 250px;
    width: 250px;
    top: 40%;
    left: 39%;
  }
  .email-icon {
    top: 80%;
    left: 47%;
  }
  .contact-icons {
    margin-top: 430px;
  }
  .contact-icons .icon-1 {
    margin-left: 410px;
  }
  .contact-icons .icon-3 {
    margin-right: 500px;
  }
}
