.icons-skills {
  background: var(--clr-grey-10);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 30vw;
  margin-bottom: 50vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
div .icon-skills-1,
.icon-skills-2,
.icon-skills-3,
.icon-skills-4,
.icon-skills-5,
.icon-skills-6,
.icon-skills-7,
.icon-skills-8,
.icon-skills-9,
.icon-skills-10,
.icon-skills-11,
.icon-skills-12,
.icon-skills-13,
.icon-skills-14,
 .icon-skills-15 {
  color: var(--clr-primary-3);
  width: 120px;
  height:60px;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

div .icon-skills-9 {
  margin-left: 4vw;
}

div .icon-skills-12 {
  margin-right: 0vw;
}

@media screen and (min-width: 768px) {
  .icons-skills {
    margin-top: 10vw;
    margin-bottom: 2vw;
  }
  div .icon-skills-9 {
    margin-left: 19vw;
  }

  div .icon-skills-13 {
    margin-right: 19vw;
  }
}

@media screen and (min-width: 992px) {
  .icons-skills {
    margin-bottom: 15vw;
  }
  div .icon-skills-9 {
    margin-left: 0vw;
  }

  div .icon-skills-13 {
    margin-right: 0vw;
  }
}
