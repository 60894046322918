.card-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 30px;
  border: 6px solid var(--clr-primary-5);
  width: 280px;
  height: 280px;
  border-radius: 50%;
  transform-style: preserve-3d;
  transition: 180ms;
  cursor: pointer;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
}

.front-image {
  border-radius: 50%;
  width: 280px;
  height: 280px;
}

.card.flip {
  --rotate-y: 180deg;
}

.card .front {
  left: 0;
}

.card .front,
.card .back {
  position: absolute;
  /* padding: 1rem; */
  backface-visibility: hidden;
}

.card .back {
  transform: rotateY(180deg);
}

.card:hover {
  --translate-y: -2px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
  border: 3px solid var(--clr-primary-5);
}

.back-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  margin: 6vw 0 0 5vw;
  text-transform: uppercase;
  color: var(--clr-grey-5);
  background: var(--clr-grey-9);
  padding: 0.375rem 0.75rem;
  border-radius: var(--radius);
}
.back-description {
  margin-top: 10px;
  width: 200px;
  margin-bottom: 1px;
}
.back-option {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 0rem; */
  margin-bottom: 0.2rem;
  color: var(--clr-grey-5);
}
.job-icon-projects {
  color: var(--clr-primary-4);
}
.back-btn {
  text-transform: uppercase;
  background: var(--clr-primary-8);
  color: hsl(210, 36%, 96%);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 0.5rem;
  border: 2px solid transparent;
  cursor: pointer;
  display: block;
  width: 6rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 0rem;
}

@media screen and (min-width: 767px) {
  .back-title {
    margin: 2vw 0 0 3vw;
  }
}

@media screen and (min-width: 992px) {
  .card-grid {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 5vw;
  }
  .card {
    width: 300px;
    height: 300px;
    justify-content: center;
    align-items: center;
  }
  .front-image {
    border-radius: 50%;
    width: 300px;
    height: 300px;
  }

  .back-title {
    margin: 12px 0 0 20px;
    background: var(--clr-white);
  }
  .back-description {
    margin-bottom: 3px;
  }
  .back-option {
    margin-top: 0.3rem;
    margin-bottom: 0.2rem;
  }
  .back-btn {
    text-transform: uppercase;
    background: var(--clr-primary-8);
    padding: 0.5rem 0.9rem;
    font-size: 0.6rem;
    width: 8rem;
    text-align: center;
    margin-top: 0.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .card-grid {
    flex-wrap: wrap;
    flex-direction: row;
  }
}
