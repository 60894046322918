h1 {
  letter-spacing: var(--spacing);
  line-height: 1.55;
  margin-bottom: 0.75rem;
}
h1 {
  text-transform: capitalize;
  font-size: 4.05rem;
  text-align: right;
  /* text-align: center; */
  color: var(--clr-primary-10);
}

.header {
  position: relative;
  height: 600px;
  background-image: linear-gradient(
    180deg,
    var(--clr-primary-4),
    var(--clr-white)
  );
}
.header h1 {
  position: absolute;
  top: 20%;
  left: 25%;
}
.header h4 {
  position: absolute;
  top: 60%;
  left: 10%;
}
.header-paragraph {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  /* border: solid 1px var(--clr-primary-5); */
  width: 300px;
}

.header-underline {
  height: 0.85rem;
  width: 15rem;
  background: var(--clr-primary-5);
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 33.5%;
  left: 28%;
  animation-name: example;
  animation-duration: 4.5s;
}
@keyframes example {
  0% {
    background-color: var(--clr-primary-6);
    left: -250px;
    top: 0%;
  }
  25% {
    background-color: var(--clr-primary-7);
    left: 80px;
    top: 89%;
  }
  50% {
    background-color: var(--clr-primary-8);
    left: 40px;
    top: 78%;
  }
  75% {
    background-color: var(--clr-primary-9);
    left: 85px;
    top: 50%;
  }
  100% {
    background-color: var(--clr-primary-8);
    left: 100px;
    top: 33.5%;
  }
}
.header .quote-icon {
  position: absolute;
  top: 53%;
  left: 1.5%;
}
.paragraph-profession {
  font-size: 1.5rem;
  position: absolute;
  width: 300px;
  color: var(--clr-primary-5);
  top: 150%;
  left: 20%;
}

/* .section {
  width: 90vw;
} */
@media screen and (min-width: 600px) {
  .header-underline {
    height: 1rem;
    width: 20rem;
    top: 35%;
    left: 15%;
    animation-name: none;
  }
}

@media screen and (min-width: 768px) {
  .header h1 {
    left: 55%;
  }
  .header-underline {
    left: 47%;
  }
  .header .quote-icon {
    left: 4%;
  }
}

@media screen and (min-width: 992px) {
  h1 {
    line-height: 1.65;
    width: 60%;
  }
  div .header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header h1 {
    left: 35%;
    top: 30%;
  }

  .header-underline {
    height: 1.5rem;
    width: 20rem;
    top: 45%;
    left: 61.5%;
    animation-name: none;
  }

  .header-paragraph {
    width: 600px;
    margin-top: 10rem;
  }

  .header .quote-icon {
    top: 80%;
  }
  /* .section-header {
    width: 100vw;
  } */
}

@media screen and (min-width: 1024px) {
  .header-underline {
    height: 1.3rem;
    width: 20rem;
    top: 45%;
    left: 62%;
    animation-name: none;
  }
  .header .quote-icon {
    position: absolute;
    top: 78%;
    left: 6%;
  }
  .header-paragraph {
    line-height: 1.35;
  }
}

@media screen and (min-width: 1440px) {
  .header-underline {
    height: 1.6rem;
    width: 28rem;
    top: 45%;
    left: 63%;
    animation-name: none;
  }
  .header-paragraph {
    line-height: 1.45;
  }
  div .header {
    margin-bottom: 200px;
  }
}
